//获取年月日时分秒
export const  formatFun =(time, format) => {
    if(!time){
        return ''
    }
    var t = time instanceof Date ? time : new Date(time);
    var tf = function(i) {
        return (i < 10 ? "0" :"") + i;
    };
    return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a) {
        switch (a) {
            case "yyyy":
                return tf(t.getFullYear());
                break;

            case "MM":
                return tf(t.getMonth() + 1);
                break;

            case "mm":
                return tf(t.getMinutes());
                break;

            case "dd":
                return tf(t.getDate());
                break;

            case "HH":
                return tf(t.getHours());
                break;

            case "ss":
                return tf(t.getSeconds());
                break;
        }
    });
}
export const queryUrlParams = (href) => {
    let url = href || window.location.href;
    if (url.indexOf('?') === -1) {
        return {};
    }
    url = url.substr(url.indexOf('?') + 1).replace(/#[^#]*]/g, '');
    const array = url.split('&');
    const param = {};
    for (let i = 0, len = array.length; i < len; i++) {
        const split = array[i].split('=');
        if(split[0] && split[1]) param[split[0]] = split[1];
    }
    return param || {};
};
export const fiveyears = (href) => {
    const arr=[]
    for (let i=0;i<5;i++){
        arr.push({
            label:new Date().getFullYear()-i+'年',
            value:new Date().getFullYear()-i
        })
    }
    arr.unshift({
        label:'不限',
        value:''
    })
    arr.push({
        label:'其他',
        value:-1
    })
    return arr
};
export const filesub = (str) => {
    let arr=str
    if(!arr) return arr
    arr=arr.substring(arr.lastIndexOf('\/') + 1)
    return arr
};
export const  validateUnifiedSocialCreditCode=(code)=>{
    if (code.length !== 18) {
        return false;
    }

    // 各位加权因子
    const weights = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28];

    // 校验字符集
    const chars = '0123456789ABCDEFGHJKLMNPQRTUWXY';

    let sum = 0;
    for (let i = 0; i < 17; i++) {
        const char = code[i];
        const charIndex = chars.indexOf(char);
        if (charIndex === -1) {
            return false;
        }
        sum += charIndex * weights[i];
    }

    const checksumIndex = (31 - (sum % 31)) % 31;
    const checksumChar = chars[checksumIndex];

    return code[17] === checksumChar;
}